import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import CameraDetail from "components/cameras/detail/CameraDetailPage";

export default function R230() {
  const renderContent = data => {
    const camera = {
      model: "R230",
      type: "Dome",
      description:
        "No wired internet, no problem. All-purpose dome camera with WiFi capabilities for easy and flexible deployments.",
      webHeader: data.webImage,
      mobileHeader: data.mobileImage,
      objectPosition: "70%",
      dims: data.dims,
      pdf: "/files/specs/R230.pdf",
      specs: {
        column1: [
          {
            title: "WiFi",
            text: "802.11ac, 802.11/g/n, 2.4Ghz and 5Ghz",
          },
          {
            title: "Resolution",
            text: "5MP sensor, 2592 x 1944 resolution",
          },
          {
            title: "Aperture",
            text: "F/1.6",
          },
          {
            title: "Pan / Tilt / Zoom",
            text: "2.8x Optical Zoom",
          },
          {
            title: "Field of View",
            text: [
              "84˚-122.2˚ Diagonal FoV",
              "53.1˚-110.1˚ Horizontal FoV",
              "38.6˚-53.1˚ Vertical FoV",
            ],
          },
          {
            title: "Focal Length",
            text: "2.8mm",
          },
          {
            title: "Dimensions",
            text: "117mm (4.61in) x 83.5mm (3.29in)",
          },
          {
            title: "Weight",
            text: "0.42kg (0.91lbs)",
          },
          {
            title: "Weather Resistance",
            text: "IP66",
          },
          {
            title: "Impact Resistance",
            text: "IK10",
          },
          {
            title: "Night Vision",
            text: "Infrared illuminators (100ft in low light)",
          },
        ],
        column2: [
          {
            title: "Onboard Storage Included",
            text: [
              "128GB or 20 days",
              "256GB or 40 days",
              "512GB or 90 days",
              "1TB or 180 days",
            ],
          },
          {
            title: "Ethernet",
            text: "10 / 100Mbps, MDI / MDI-X Auto Sensing",
          },
          {
            title: "Power-Over-Ethernet",
            text: "Standard 802.3af for one-cable installation",
          },
          {
            title: "Power Consumption",
            text: "Max 9.4 watts",
          },
          {
            title: "Operating Temperature",
            text: ["-55 ̊ to 55  ̊C", "-67 ̊ to 131  ̊F"],
          },
          {
            title: "Operating Humidity",
            text: "10-90%, no condensation",
          },
          {
            title: "In the Box",
            text: "Mounting screws and anchors, RJ45 waterproofing grommet",
          },
          {
            title: "Baseline Steady State",
            text: "10 - 30 Kbps per camera",
          },
          {
            title: "HD WAN Live Streaming",
            text: "530 - 720 Kbps per camera",
          },
          {
            title: "Rhombus Sensor Network Compatible",
          },
          {
            title: "FCC, CE, IC, RCM, UKCA, TAA Compliant",
          },
          {
            title: "10-Year Warranty Included",
          },
        ],
      },
      accessoryCompatible: true,
      sensorCompatible: true,
      accessories: [
        "ACCR23CAP",
        "ACCR23JB",
        "ACCR2CB",
        "ACCR2ARM",
        "ACCR2CM",
        "ACCR2PB",
        "ACCROD40CM",
        "ACCDCM1",
      ],
      exploreModels: {
        title: "Explore Other Dome Cameras",
        subtitle:
          "Find the solution that’s best suited for your needs. Compare all camera models or view other dome cameras below.",
        cameraCards: [
          {
            image: data.r120200Camera,
            mobileImage: data.r120200CameraMobile,
            alt: "R120 dome camera model",
            width: "189px",
            model: "R120",
            useCase: "Ideal for maximizing coverage.",
            link: "/cameras/dome-cameras/r120",
          },
          {
            image: data.r120200Camera,
            mobileImage: data.r120200CameraMobile,
            alt: "R200 dome camera model",
            width: "189px",
            model: "R200",
            useCase: "Ideal for general visibility.",
            link: "/cameras/dome-cameras/r200",
          },
          {
            image: data.r170Camera,
            mobileImage: data.r170CameraMobile,
            alt: "R170 dome camera model",
            width: "195px",
            model: "R170",
            useCase: "Ideal for discreet visibility.",
            link: "/cameras/dome-cameras/r170",
          },
          {
            image: data.r400Camera,
            mobileImage: data.r400CameraMobile,
            alt: "R400 dome camera model",
            width: "183px",
            model: "R400",
            useCase: "Ideal for use in entryways.",
            link: "/cameras/dome-cameras/r400",
          },
        ],
      },
    };
    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus R230 WiFi Dome Security Camera</title>
          <meta
            name="description"
            content="WiFi Enabled 5MP dome video surveillance camera with modern cloud management, edge-based analytics, and durable design."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/r230-seo.png"
          />
        </Helmet>
        <CameraDetail camera={camera} />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r230-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r230-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/detail/img/r230-dims-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      r120200Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r120-200-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r120200CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r120-200-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r170Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r170-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r170CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r170-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r400Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r400-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r400CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r400-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
